import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./VerificationScreen.css"; // CSS file for styling
import { BsArrowLeftShort } from "react-icons/bs";
import { doc, getDoc, setDoc, onSnapshot } from "firebase/firestore"; // Import Firestore and necessary functions
import {
  sendOtp,
  confirmationResult,
  resetRecaptcha,
} from "../../services/otpService";
import { firestore } from "../../firebase";
import global from '../../global';

const VerificationScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [otpInput1, setOtpInput1] = useState("");
  const [otpInput2, setOtpInput2] = useState("");
  const [otpInput3, setOtpInput3] = useState("");
  const [otpInput4, setOtpInput4] = useState("");
  const [otpInput5, setOtpInput5] = useState("");
  const [otpInput6, setOtpInput6] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [resendMessage, setResendMessage] = useState("");
  const { phoneNumber, registerName } = state;

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);
  const hiddenInputRef = useRef(null);

  useEffect(() => {
    if (!confirmationResult || !phoneNumber) {
      navigate("/login"); // Redirect to login if confirmationResult is not set
    }
  }, [navigate, phoneNumber]);

  useEffect(() => {
    console.log("Page loaded VerificationScreen...");

    // Temporarily show hidden input for iOS Safari to detect OTP autofill
    if (hiddenInputRef.current) {
      hiddenInputRef.current.style.opacity = "1";
      hiddenInputRef.current.style.pointerEvents = "auto";

      setTimeout(() => {
        hiddenInputRef.current.style.opacity = "0";
        hiddenInputRef.current.style.pointerEvents = "none";
      }, 2000); // Delay hiding to ensure detection
    }

    // Web OTP API for Android Chrome
    if ("OTPCredential" in window) {
      const ac = new AbortController();
      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal,
        })
        .then((otpCredential) => {
          if (otpCredential?.code) {
            fillOTPFields(otpCredential.code);
          }
        })
        .catch((err) => console.log("OTP Auto-read failed: ", err));

      return () => ac.abort();
    }
  }, []);

  // Function to handle auto-filling all six input fields
  const fillOTPFields = (otp) => {
    if (otp.length === 6) {
      setOtpInput1(otp[0]);
      setOtpInput2(otp[1]);
      setOtpInput3(otp[2]);
      setOtpInput4(otp[3]);
      setOtpInput5(otp[4]);
      setOtpInput6(otp[5]);

      input6Ref.current.focus(); // Move focus to last field
      handleContinue(otp); // Auto-submit OTP
    }
  };

  const handleHiddenInputChange = (e) => {
    const otp = e.target.value.trim();
    if (otp.length === 6) {
      fillOTPFields(otp);
    }
  };

  const handleResend = async () => {
    setIsLoading(true);
    try {
      const confirmation = await sendOtp(phoneNumber);
      // Do something with confirmation

      setAlertMessage("");
      setResendMessage(
        "A new confirmation code has been resent to " + phoneNumber
      );

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setResendMessage("");
      setAlertMessage("Error resending verification code");
      console.log("error resending verification code: ", error, phoneNumber);
      // Handle error
    }
  };

  const handleKeyDown = (e, prevRef, setter) => {
    if (e.key === "Backspace" && !e.target.value) {
      // Set the previous input value to an empty string
      setter("");
      // Move the focus to the previous input if it exists
      if (prevRef && prevRef.current) {
        prevRef.current.focus();
      }
    }
  };

  const handleContinue = (code) => {
    console.log("handleContinue: ", code);

    if (code.length === 6) {
      // Ensure the OTP length is 6 before proceeding
      setIsLoading(true);
      // Use the code state that contains the user inputted code
      confirmationResult
        .confirm(code)
        .then(async (result) => {
          // User signed in successfully.
          const user = result.user;
          console.log("User signed in: ", user);

          const userLogin = {
            accessToken: user.accessToken,
            refreshToken: user.refreshToken,
            uid: user.uid,
          };

          localStorage.setItem("userLogin", JSON.stringify(userLogin));

          // Get User Data from Firebase using accessToken and uid
          if (registerName) {
            await storeUserData(user);
          } else {
            await getUserData(user);
          }
        })
        .catch((error) => {
          setResendMessage("");
          setAlertMessage("Invalid code.");

          // Handle error - bad verification code, etc.
          console.error("Error during confirmation: ", error);
        })
        .finally(() => {
          setIsLoading(false); // Ensure loading is stopped regardless of outcome
        });
    } else {
      setResendMessage("");
      setAlertMessage("Please enter a 6-digit verification code.");
    }
  };

  const handleContinueClick = () => {
    // Concatenate all OTP input values to form the full code
    const code = otpInput1 + otpInput2 + otpInput3 + otpInput4 + otpInput5 + otpInput6;
    // Call handleContinue with the full code
    handleContinue(code);
  };
  

  const storeUserData = async (user) => {
    const date = new Date();
    const userdata = {
      name: registerName,
      phone: user.phoneNumber,
      role: "rider",
      image: "https://www.pngitem.com/pimgs/m/256-2560275_avatar-icon-red-png-clipart-png-download-red.png",
    };
    userdata.id = user.uid;
    userdata.createdDate = date;
    userdata.updatedDate = date;
    console.log("storing user data: ", userdata);
    await setDoc(doc(firestore, "User", user.uid), userdata);
    setGlobalUser(user);
    navigate("/home");
  };

  const getUserData = async (user) => {
    // Access Firestore
    const userRef = doc(firestore, "User", user.uid); // Reference to the user document in Firestore
    const docSnap = await getDoc(userRef);

    if (docSnap.exists()) {
      setGlobalUser(user);
      console.log("User data:", docSnap.data()); // Here you have the user data
      // Navigate to home or next page as needed
      navigate("/home");
    } else {
      setResendMessage("");
      setAlertMessage("No user data exist");
      console.log("No such user data!");
      // Handle the case where the user data does not exist in Firestore
    }
  };

  const setGlobalUser = (user) => {
    const userRef = doc(firestore, "User", user.uid); // Reference to the user document in Firestore
    const unsub = onSnapshot(userRef, (doc) => {
      global.user = doc.data();
      console.log("Current data: ", doc.data());
    });
  }

  const handleOTPChange = (setter, nextRef, currentIndex) => (e) => {
    const { value } = e.target;
    setter(value);

    // Ensure iOS Safari detects the hidden input for autofill
    if (hiddenInputRef.current) {
      hiddenInputRef.current.focus();
    }

    // Construct the full code using the current value and other state values
    const fullCode = [
      otpInput1,
      otpInput2,
      otpInput3,
      otpInput4,
      otpInput5,
      otpInput6,
    ];

    // Update the current digit in the full code array
    fullCode[currentIndex] = value;

    if (value.length >= 1 && nextRef) {
      nextRef.current.focus(); // Move to the next input field
    }

    // If it's the last input, call handleContinue with the full code
    if (currentIndex === 5) {
      handleContinue(fullCode.join(""));
    }
  };

  return (
    <div className="wrapper">
      <div className="verification-container">
        <div className="header">
          <button className="back-button" onClick={() => navigate(-1)}>
            <BsArrowLeftShort className="back-button" />
          </button>
          <h1 className="verifynavigation-button-label m-2">Verification</h1>
        </div>
        <div className="new-content">
          <p>Enter Verification Code</p>
          {resendMessage && (
            <div className="successMessageStyle">{resendMessage}</div>
          )}
          {alertMessage && (
            <div className="alertMessageStyle">{alertMessage}</div>
          )}

          <p className="text2">
            A 6 digit code has been sent to your phone number
          </p>

          {/* Hidden Input for iOS Safari & Chrome */}
          <input
            ref={hiddenInputRef}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            autoComplete="one-time-code"
            style={{
              position: "absolute",
              opacity: 0,
              pointerEvents: "none",
              height: "1px", // Ensure it's detectable by Safari
              width: "1px",  // Ensure it's detectable by Safari
              top: "-9999px", // Move it out of view but still detected
          }}
            onChange={handleHiddenInputChange}
          />

          <div className="input-wrapper">
            <input

              ref={input1Ref}
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              className="otp-input"
              value={otpInput1}
              maxLength={1}
              onChange={handleOTPChange(setOtpInput1, input2Ref, 0)}
            />
            <input
              ref={input2Ref}
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              className="otp-input"
              value={otpInput2}
              maxLength={1}
              onChange={handleOTPChange(setOtpInput2, input3Ref, 1)}
              onKeyDown={(e) => handleKeyDown(e, input1Ref, setOtpInput1)}
            />
            <input
              ref={input3Ref}
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              className="otp-input"
              value={otpInput3}
              maxLength={1}
              onChange={handleOTPChange(setOtpInput3, input4Ref, 2)}
              onKeyDown={(e) => handleKeyDown(e, input2Ref, setOtpInput2)}
            />
            <input
              ref={input4Ref}
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              className="otp-input"
              value={otpInput4}
              maxLength={1}
              onChange={handleOTPChange(setOtpInput4, input5Ref, 3)}
              onKeyDown={(e) => handleKeyDown(e, input3Ref, setOtpInput3)}
            />
            <input
              ref={input5Ref}
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              className="otp-input"
              value={otpInput5}
              maxLength={1}
              onChange={handleOTPChange(setOtpInput5, input6Ref, 4)}
              onKeyDown={(e) => handleKeyDown(e, input4Ref, setOtpInput4)}
            />
            <input
              ref={input6Ref}
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              className="otp-input"
              value={otpInput6}
              maxLength={1}
              onChange={handleOTPChange(setOtpInput6, null, 5)}
              onKeyDown={(e) => handleKeyDown(e, input5Ref, setOtpInput5)}
            />
          </div>

          <p className="resend mt-5">
            Didn’t receive a code?{" "}
            <span className="resend-link" onClick={handleResend}>
              Resend
            </span>
          </p>
          <div className="verifbuttondiv">
            <button onClick={handleContinueClick} className="verifbuttonStyle">
              Continue
            </button>
          </div>
        </div>

        {isLoading && (
          <div className="loading-overlay">
            <div className="loader"></div>
            <p>Please wait...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerificationScreen;
