import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ContactUs.css";
import { BsArrowLeftShort, BsFillRecord2Fill } from "react-icons/bs";
import { IoMdCall, IoMdMail } from "react-icons/io";
import { fetchUserData } from "../../services/userService";
import { collection, addDoc } from "firebase/firestore";
import { firestore } from "../../firebase";

const ContactUs = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [fname, setFname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);

    fetchUserData().then((userData) => {
      setFname(userData.name || "");
      setEmail(userData.email || "");
      setPhone(userData.phone || "");

      setLoading(false);
    });
  }, []);

  /*
  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      await addDoc(collection(firestore, "Inbox"), {
        name: fname,
        email: email,
        phone: phone,
        message: message,
        createdAt: new Date(),
      });
      navigate("/home");
    } catch (error) {
      console.error("Error submitting message: ", error);
      setError("There was an error submitting your message. Please try again.");
      setLoading(false);
    }
  };
  */

  return (
    <div className="home-screen">
      <div className="">
        <div className="contactheader mb-3">
          <button className="contactback-button" onClick={() => navigate(-1)}>
            <BsArrowLeftShort className="contactback-button" />
          </button>
          <h1 className="contacttext mt-3">Contact Us</h1>
        </div>
        {error && <div className="error-message">{error}</div>}
        <div className="issuetext">Let us know your issue & feedback</div>
        <div className="icontextdiv">
          <IoMdCall className="callcircle" />
          <a href="tel:+18767773991" className="numbertext">
            +1 (876) 777-3991
          </a>
        </div>
        <div className="mailicondiv mb-5">
          <IoMdMail className="callcircle" />
          <a href="mailto:devteam@rajlo.com" className="numbertext">devteam@rajlo.com</a>
        </div>
        {/*
        <div className="sendtext">Or send your message</div>
        <div style={{ padding: "20px" }}>
          {fullName()}
          {emailfun()}
          {yourMessage()}
        </div>
        */}
      </div>
      {/*<div className="contactbuttondiv">{saveButton()}</div>*/}
    </div>
  );

  function fullName() {
    return (
      <div className="mb-4">
        <div className="contactlabel-container">
          <label className="contactlabeltext">Full Name</label>
        </div>
        <div className="contactnameInputStyle">{fname}</div>
      </div>
    );
  }

  function emailfun() {
    return (
      <div className="mb-4">
        <div className="contactlabel-container">
          <label className="contactlabeltext">Email Address</label>
        </div>
        <div className="contactnameInputStyle">{email}</div>
      </div>
    );
  }

  function yourMessage() {
    return (
      <div className="mb-4">
        <div className="contactlabel-container">
          <label className="contactlabeltext">Your Message</label>
        </div>
        <div className="contactnameInputContainer">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Write your message here"
            className="textareaInputStyle"
          />
        </div>
      </div>
    );
  }
  /*
  function saveButton() {
    return (
      <button
        className="contactcontinue"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? "Submitting..." : "Submit"}
      </button>
    );
  }
 */
};

export default ContactUs;
